import axios from 'axios';
import { URLs } from '../constants';

export const authClient = {
  getIPlanetToken: async ({ login, password }) =>
    axios.post(
      URLs.IPLANET_PATH(),
      {},
      {
        headers: {
          'X-OpenAM-Username': login,
          'X-OpenAM-Password': password,
        },
      }
    ),
  getJWTToken: async ({ iPlanetToken, partnerId }) =>
    axios.get(URLs.JWT_TOKEN_PATH(partnerId), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json; charset=UTF-8',
        iPlanetDirectoryPro: iPlanetToken,
      },
    }),
};
