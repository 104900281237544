import Container from '@mui/material/Container';
import { LoginForm } from './components/login-form/login-form';
import { getTheme } from '@cw-ui/asio-neon-themes';
import { ThemeProvider } from '@mui/material/styles';
import { RootContainerStyled, Header, Chat } from './components';
import { MessagesContext, useMessages, useAuth, AuthContext } from './hooks';

const theme = getTheme('light');

theme.typography.fontSize = 10;
theme.typography.body1.fontSize = 14;
theme.typography.body2.fontSize = 12;
theme.typography.body3 = { ...theme.typography.body2, fontSize: 10 };

export function Application() {
  const endpointId =
    new URLSearchParams(document.location.search).get('endpointId') || '';
  const ignoreLogin = !!new URLSearchParams(document.location.search).get(
    'ignoreLogin'
  );
  const authContextValue = useAuth(endpointId);
  const messagesContextValue = useMessages(authContextValue);

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="md">
        <AuthContext.Provider value={authContextValue}>
          <MessagesContext.Provider value={messagesContextValue}>
            <RootContainerStyled>
              {!!authContextValue[0] || ignoreLogin ? (
                <>
                  <Header />
                  <Chat />
                </>
              ) : (
                <LoginForm />
              )}
            </RootContainerStyled>
          </MessagesContext.Provider>
        </AuthContext.Provider>
      </Container>
    </ThemeProvider>
  );
}
