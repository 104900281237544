import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Application } from './application';

const root = ReactDOM.createRoot(document.getElementById('root'));
try {
  fetch('./src/env-config.json')
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      window.ENV_CONFIG = data;
      render();
    })
    .catch(() => {
      console.log(`ENV file not found at public/src/env-config.json`);
    });
} catch (error) {
  console.log(`ENV file not found at public/src/env-config.json`);
}

const render = () =>
  root.render(
    // <React.StrictMode>
    <Application />
    // </React.StrictMode>
  );
