import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getEnvData } from '../constants';
import { authClient } from '../client';

export function useAuth(endpointID) {
  const isLoading = useRef(false);
  const [userLogin, setUserLogin] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPartnerId, setUserPartnerId] = useState('');
  const [jwtToken, setJWTToken] = useState(null);
  const [endpointId, setEndpointId] = useState(endpointID);

  const setLoginParams = ({
    login = '',
    password = '',
    partnerId = '',
    token = '',
  }) => {
    setUserLogin(login);
    setUserPassword(password);
    setUserPartnerId(partnerId);
    setJWTToken(token);
  };

  const refreshToken = async () => {
    const login = userLogin || getEnvData().userLogin;
    const password = userPassword || getEnvData().userPassword;
    const partnerId = userPartnerId || getEnvData().partnerId;
    if (!isLoading.current && login && password && partnerId) {
      console.log('Get token');
      try {
        isLoading.current = true;
        let response = await authClient.getIPlanetToken({ login, password });
        const iPlanetToken = response?.data?.tokenId;
        response = await authClient.getJWTToken({ iPlanetToken, partnerId });
        const receivedJWTToken = response?.data?.token;
        const elemests = atob(receivedJWTToken.split('.')[1]);
        const expirationDate = new Date(JSON.parse(elemests).exp * 1000);
        const dateNow = new Date();
        console.log('Exp:', new Date(JSON.parse(elemests).exp * 1000));
        setJWTToken(receivedJWTToken);
        const refreshInMs = expirationDate - dateNow;
        console.log('Refresh token after ms', refreshInMs);
        setTimeout(
          () => {
            refreshToken();
          },
          refreshInMs < 0 ? 0 : refreshInMs
        );
        isLoading.current = false;
        return receivedJWTToken;
      } catch (error) {
        isLoading.current = false;
        setLoginParams({});
        setJWTToken('');
        console.log('Error during fetching token', error);
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    if (jwtToken) {
      const elements = atob(jwtToken.split('.')[1]);
      const expirationDate = new Date(JSON.parse(elements).exp * 1000);
      if (new Date() > expirationDate) {
        refreshToken();
      }
    } else {
      refreshToken();
    }
  }, [userLogin, userPassword, userPartnerId]);

  return [
    jwtToken,
    endpointId,
    setJWTToken,
    setEndpointId,
    [[userLogin, userPassword, userPartnerId], setLoginParams],
  ];
}

const initialAuthContext = [
  null,
  null,
  () => {},
  () => {},
  [[null, null, null], () => {}],
];

export const AuthContext = createContext(initialAuthContext);

export function useAuthContext() {
  return useContext(AuthContext);
}
