import { createSvgIcon } from '@mui/material';

export const BotAvatarIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.500977" width="31" height="31" rx="15.5" fill="#E9F2F5" />
    <path
      d="M21.3337 14.3346V13.0013C21.3337 12.268 20.7337 11.668 20.0003 11.668H18.0003C18.0003 10.5613 17.107 9.66797 16.0003 9.66797C14.8937 9.66797 14.0003 10.5613 14.0003 11.668H12.0003C11.267 11.668 10.667 12.268 10.667 13.0013V14.3346C9.56033 14.3346 8.66699 15.228 8.66699 16.3346C8.66699 17.4413 9.56033 18.3346 10.667 18.3346V21.0013C10.667 21.7346 11.267 22.3346 12.0003 22.3346H20.0003C20.7337 22.3346 21.3337 21.7346 21.3337 21.0013V18.3346C22.4403 18.3346 23.3337 17.4413 23.3337 16.3346C23.3337 15.228 22.4403 14.3346 21.3337 14.3346ZM20.0003 21.0013H12.0003V13.0013H20.0003V21.0013ZM14.0003 17.0013C13.447 17.0013 13.0003 16.5546 13.0003 16.0013C13.0003 15.448 13.447 15.0013 14.0003 15.0013C14.5537 15.0013 15.0003 15.448 15.0003 16.0013C15.0003 16.5546 14.5537 17.0013 14.0003 17.0013ZM19.0003 16.0013C19.0003 16.5546 18.5537 17.0013 18.0003 17.0013C17.447 17.0013 17.0003 16.5546 17.0003 16.0013C17.0003 15.448 17.447 15.0013 18.0003 15.0013C18.5537 15.0013 19.0003 15.448 19.0003 16.0013ZM13.3337 18.3346H18.667V19.668H13.3337V18.3346Z"
      fill="#0885AF"
    />
    <rect x="0.5" y="0.500977" width="31" height="31" rx="15.5" stroke="#A8CBD7" />
  </svg>,
  'BotAvatar'
);
