import React from 'react';
import { Fragment, useRef, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import { InputAreaWithMemo } from '../input-area';
import { MessageWithMemo } from '../message';
import { FeedbackDialog } from '../feedback-dialog';
import { useMessageContext } from '../../hooks/use-messages';
import { useAuthContext } from '../../hooks';
import { formatDate, getIsConversationFinished } from '../../utils';

export function Chat() {
  const [messages, addMessage, sessionId, executing] = useMessageContext();
  const [jwtToken] = useAuthContext();
  const messageListReference = useRef(null);
  const [showFeedback, setShowFeedback] = React.useState(false);
  useEffect(() => {
    const lastChildElement = messageListReference.current?.lastElementChild;
    if (lastChildElement?.scrollIntoView) {
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const elements = messages.map((message, index) => {
    const next = messages[index + 1];

    const isLast = !next;
    const isClosing = next && next.issuer !== message.issuer;
    const isSender = message.issuer === 'user';

    return (
      <Fragment key={message.id}>
        <MessageWithMemo
          message={message.message}
          isLastFromGroup={isLast || isClosing}
          isSender={isSender}
          onClick={() => setShowFeedback(true)}
          showFeedback={
            !!isLast && getIsConversationFinished(message.status) && !!sessionId
          }
        />
        {(isLast || isClosing) && (
          <Typography
            variant="body3"
            sx={{
              mb: 1,
              marginLeft: isSender ? 'auto' : 'none',
              color: 'text.disabled',
            }}
          >
            {formatDate(message?.message?.createdAt)}
          </Typography>
        )}
        {getIsConversationFinished(message.status) && (
          <Divider
            sx={{
              width: '100%',
              '&::before, &::after': {
                borderColor: 'secondary.light',
              },
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              End of conversation
            </Typography>
          </Divider>
        )}
        {isClosing && <Box sx={{ mb: 4 }} />}
      </Fragment>
    );
  });
  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: 'auto', px: 5 }}>
        <Container maxWidth="sm">
          {elements.length === 0 ? (
            <Typography
              variant="body1"
              component="div"
              align="center"
              sx={{ p: 2, color: 'text.disabled' }}
            >
              Please enter your message to start conversation
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
              }}
              ref={messageListReference}
            >
              {elements}
            </Box>
          )}
        </Container>
      </Box>
      {executing && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ backgroundColor: 'background.paper', py: 2 }}>
        <Container maxWidth="sm">
          <InputAreaWithMemo
            onSendMessage={addMessage}
            disabled={!jwtToken || executing}
          />
        </Container>
      </Box>
      <FeedbackDialog
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
    </>
  );
}
