export function formatMessages(response, counter) {
  const responseMessages = response.messages?.filter(
    (responseMessage) => responseMessage.role === 'assistant'
  );
  const status = response.status;
  return responseMessages.map((responseMessage, index) => ({
    id: counter + index + 1,
    issuer: 'bot',
    status,
    message: {
      text: responseMessage.content,
      createdAt: responseMessage?.createdAt,
    },
  }));
}
