import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import Button from '@mui/material/Button';

export function SpeechInput({
  onSpeechInput = () => {},
  onSpeechEnd = () => {},
  language = 'en-US',
  disabled = false,
}) {
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState('');
  // const [interimTranscript, setInterimTranscript] = useState("");
  const recognitionReference = useRef(null);

  const supportsSpeechRecognition = () =>
    'webkitSpeechRecognition' in window || 'SpeechRecognition' in window;

  const capitalize = (s) => s.replace(/\S/, (m) => m.toUpperCase());

  const onRecognitionStart = () => {
    setIsRecognizing(true);
    setFinalTranscript('');
    // setInterimTranscript("");
  };
  const onRecognitionResult = (event) => {
    let interimTranscript = '';
    let finalTranscriptTemporary = '';
    for (let index = event.resultIndex; index < event.results.length; ++index) {
      if (event.results[index].isFinal) {
        finalTranscriptTemporary += event.results[index][0].transcript;
      } else {
        interimTranscript += event.results[index][0].transcript;
      }
    }
    onSpeechInput(
      capitalize(finalTranscript + finalTranscriptTemporary + interimTranscript)
    );
    setFinalTranscript((previous) => previous + finalTranscriptTemporary);
    // setInterimTranscript(interimTranscript);
  };

  const onRecognitionEnd = () => {
    setIsRecognizing(false);
    recognitionReference.current.stop();
    onSpeechEnd();
  };

  const onRecognitionError = (event) => {
    console.error('Recognition error', event.error);
  };

  const startRecognition = () => {
    if (isRecognizing) {
      recognitionReference.current.stop();
      return;
    }
    recognitionReference.current.start();
  };

  useEffect(() => {
    if (!supportsSpeechRecognition()) return;

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionReference.current = new SpeechRecognition();
    recognitionReference.current.lang = language;
    recognitionReference.current.continuous = false;
    recognitionReference.current.interimResults = true;
    recognitionReference.current.onstart = onRecognitionStart;
    recognitionReference.current.onresult = onRecognitionResult;
    recognitionReference.current.onerror = onRecognitionError;
    return () => {
      recognitionReference.current = null;
    };
  }, []);

  useEffect(() => {
    if (recognitionReference.current) {
      recognitionReference.current.onend = onRecognitionEnd;
    }
  }, [onSpeechEnd]);

  return supportsSpeechRecognition() ? (
    <Button
      variant="contained"
      sx={{ marginLeft: 1 }}
      onClick={startRecognition}
      size="small"
      disabled={disabled}
    >
      {isRecognizing ? <StopIcon /> : <MicIcon />}
    </Button>
  ) : null;
}

SpeechInput.propTypes = {
  onSpeechInput: PropTypes.func,
  onSpeechEnd: PropTypes.func,
  language: PropTypes.string,
  disabled: PropTypes.bool,
};
