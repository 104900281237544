import axios from 'axios';
import { URLs } from '../constants';

export const feedbackClient = {
  sendFeedback: async ({ message, rating, sessionId }) =>
    axios.post(URLs.SEND_FEEDBACK(sessionId), {
      rating,
      content: message.trim(),
    }),
};
