import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const PaperMessageStyled = styled(Paper)(
  ({ theme, issender, islast }) => {
    const bgc = issender
      ? theme.palette.background.paper
      : theme.palette.background.backgroundMidnightDark;
    const color = issender
      ? theme.palette.text.primary
      : theme.palette.text.textInverse;
    const ml = issender ? 'auto' : 'none';
    const br = theme.shape.borderRadius * 2;

    return {
      ...theme.typography.body2,
      flexGrow: 1,
      backgroundColor: bgc,
      padding: theme.spacing(1, 2),
      maxWidth: 400,
      color,
      marginBottom: theme.spacing(1),
      marginLeft: ml,
      borderRadius: br,
      borderBottomLeftRadius: !issender && islast ? 0 : br,
      borderBottomRightRadius: issender && islast ? 0 : br,
      '&:first-of-type': {
        marginTop: theme.spacing(1),
      },
      position: 'relative',
    };
  }
);
