import { feedbackClient } from '../client';
import { useMessageContext } from './use-messages';

export function useFeedback() {
  const sessionId = useMessageContext()[2];
  const sendFeedback = (message, rating) => {
    try {
      feedbackClient.sendFeedback({ message, rating, sessionId });
    } catch (error) {
      console.log('Error while sendind feedback', error);
    }
  };
  return { sendFeedback };
}
