import { memo, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import SendIcon from '@mui/icons-material/Send';
import { SpeechInput } from '../speech-input/speech-input';

const TextFieldStyled = styled(TextField)(() => ({
  '.MuiInputBase-root': { fontSize: 12 },
  '.MuiInputLabel-root': { fontSize: 12 },
}));

export function InputArea({ onSendMessage = () => {}, disabled = false }) {
  const [value, setValue] = useState('');

  const sendMessage = useCallback(() => {
    if (value?.trim().length && !disabled) {
      onSendMessage(value);
      setValue('');
    }
  }, [value, disabled, onSendMessage]);

  const onKeyHandler = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TextFieldStyled
        size="small"
        margin="none"
        fullWidth
        label="Type your message..."
        onKeyDown={onKeyHandler}
        value={value}
        onChange={(error) => setValue(error.target.value)}
        inputProps={{ maxLength: 4096 }}
      />
      <Button
        variant="contained"
        sx={{ marginLeft: 1 }}
        onClick={sendMessage}
        size="small"
        disabled={disabled}
      >
        <SendIcon />
      </Button>
      <SpeechInput
        disabled={disabled}
        onSpeechInput={setValue}
        onSpeechEnd={sendMessage}
      />
    </Box>
  );
}

InputArea.propTypes = {
  onSendMessage: PropTypes.func,
  disabled: PropTypes.bool,
};

export const InputAreaWithMemo = memo(InputArea);
