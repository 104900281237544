import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthContext } from '../../hooks';

export function LoginForm() {
  const [, , , , [[enteredLogin], setLoginData]] = useAuthContext();
  const [userLogin, setUserLogin] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPartnerId, setUserPartnerId] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [partnerIdError, setPartnerIdError] = useState(false);

  const handleSubmit = () => {
    if (userLogin && userPassword && userPartnerId) {
      setLoginData({
        login: userLogin,
        password: userPassword,
        partnerId: userPartnerId,
      });
    } else {
      setLoginError(!userLogin);
      setPasswordError(!userPassword);
      setPartnerIdError(!userPartnerId);
    }
  };

  const handleLoginChange = (value) => {
    setUserLogin(value);
    setLoginError(!value);
  };

  const handlePasswordChange = (value) => {
    setUserPassword(value);
    setPasswordError(!value);
  };

  const handleUserPartnerIdChange = (value) => {
    setUserPartnerId(value);
    setPartnerIdError(!value);
  };

  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <TextField
            error={loginError}
            required
            label="Login"
            autoFocus
            margin="dense"
            id="userLogin"
            type="text"
            fullWidth
            variant="outlined"
            value={userLogin}
            onChange={(event) => handleLoginChange(event.target.value)}
          />
          <TextField
            error={passwordError}
            required
            label="Password"
            margin="dense"
            id="userPassword"
            type="password"
            fullWidth
            variant="outlined"
            value={userPassword}
            onChange={(event) => handlePasswordChange(event.target.value)}
          />
          <TextField
            error={partnerIdError}
            required
            label="Partner ID"
            margin="dense"
            id="userPartnerId"
            type="text"
            fullWidth
            variant="outlined"
            value={userPartnerId}
            onChange={(event) => handleUserPartnerIdChange(event.target.value)}
          />
          <Backdrop open={!!enteredLogin}>
            <CircularProgress />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} size="medium">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LoginForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
