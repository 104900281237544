import axios from 'axios';
import { URLs } from '../constants';

export const messageClient = {
  createInitialMessage: async ({ endpointId, partnerId, token }) =>
    axios.post(
      URLs.INITIAL_MESSAGE(partnerId),
      {
        endpointId,
      },
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          // 'sso-token': token,
          iplanetdirectorypro: token,
        },
      }
    ),
  sendMessage: async ({ message, sessionId, token }) =>
    axios.post(
      URLs.SEND_MESSAGE(sessionId),
      {
        message: [
          {
            role: 'user',
            content: message.text.trim(),
            created_at: message.createdAt,
          },
        ],
      },
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          // 'sso-token': token,
          iplanetdirectorypro: token,
        },
      }
    ),
};
