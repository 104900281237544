import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Feedback from '@mui/icons-material/Feedback';

import { BotAvatarIcon } from './bot-avatar-icon';
import { PaperMessageStyled } from './message.styled';

const messagePropTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
  isSender: PropTypes.bool,
  showFeedback: PropTypes.bool,
  isLastFromGroup: PropTypes.bool,
  onClick: PropTypes.func,
};

export function Message({
  message,
  isSender = false,
  isLastFromGroup = false,
  showFeedback = false,
  onClick = () => {},
}) {
  const avatar =
    isLastFromGroup && !isSender ? (
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '-48px',
        }}
      >
        <BotAvatarIcon
          sx={{ fontSize: 32, color: 'support.supportInfoInverse' }}
        />
      </Box>
    ) : null;

  const label = message.label ? (
    <Typography variant="body3" component="div" sx={{ mb: 1 }}>
      {message.label}
    </Typography>
  ) : null;

  const content = useMemo(() => {
    if (showFeedback) {
      return (
        <>
          <Typography variant="body2">{message.text}</Typography>
          <Link component="button" color="link.linkPrimary" onClick={onClick}>
            Leave feedback
            <Feedback />
          </Link>
        </>
      );
    }

    return <Typography variant="body2">{message.text}</Typography>;
  }, [message, showFeedback, onClick]);

  return (
    <PaperMessageStyled
      issender={isSender}
      islast={isLastFromGroup}
      data-testid="message"
    >
      {avatar}

      {label}

      <Box
        sx={message.label && { p: 2, backgroundColor: 'background.default' }}
      >
        {content}
      </Box>
    </PaperMessageStyled>
  );
}

Message.propTypes = messagePropTypes;

export const MessageWithMemo = memo(Message);
