import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthContext } from '../../hooks';

export function LoginDialog({ open, onClose = () => {} }) {
  const [authToken, authEndpointId, saveJWTToken, saveEndpointId] =
    useAuthContext();
  const [jwtToken, setJWTtoken] = useState('');
  const [endpointId, setEndpointId] = useState('');

  useEffect(() => {
    if (open) {
      setJWTtoken(authToken);
      setEndpointId(authEndpointId);
    }
  }, [open]);

  useEffect(() => {
    setJWTtoken(authToken);
  }, [authToken]);

  const handleClose = () => {
    setJWTtoken('');
    setEndpointId('');
    onClose();
  };

  const handleSendEndpointID = () => {
    if (endpointId && endpointId.trim()) {
      console.log('Saving endpointId:', endpointId);
      saveEndpointId(endpointId);
    }
  };

  const handleSendToken = () => {
    if (jwtToken && jwtToken.trim()) {
      console.log('Saving jwt token:', jwtToken);
      saveJWTToken(jwtToken);
    }
  };

  const onKeyHandlerToken = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      event.preventDefault();
      event.stopPropagation();
      handleSendToken();
    }
  };

  const onKeyHandlerEndpointId = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      event.preventDefault();
      event.stopPropagation();
      handleSendEndpointID();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Login params</DialogTitle>
        <DialogContent>
          <TextField
            label="EndpointId"
            autoFocus
            margin="dense"
            id="endpointId"
            type="text"
            fullWidth
            variant="outlined"
            rows={1}
            multiline
            value={endpointId}
            onChange={(error) => setEndpointId(error.target.value)}
            onKeyDown={onKeyHandlerEndpointId}
          />
          <Button
            onClick={handleSendEndpointID}
            size="small"
            variant="contained"
            disabled={!endpointId || endpointId === authEndpointId}
          >
            Save EndpointID
          </Button>
          <TextField
            label="Token"
            autoFocus
            margin="dense"
            id="jwtToken"
            type="text"
            fullWidth
            variant="outlined"
            rows={10}
            multiline
            value={jwtToken}
            onChange={(error) => setJWTtoken(error.target.value)}
            onKeyDown={onKeyHandlerToken}
          />
          <Button
            onClick={handleSendToken}
            size="small"
            variant="contained"
            disabled={!jwtToken || jwtToken === authToken}
          >
            Save Token
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
