import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import { useFeedback } from '../../hooks';

export function FeedbackDialog({ open, onClose = () => {} }) {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(null);
  const { sendFeedback } = useFeedback();

  const handleClose = () => {
    setFeedback('');
    setRating(null);
    onClose();
  };

  const handleSend = () => {
    if (feedback.trim()) {
      console.log('Posting feedback:', feedback);
      sendFeedback(feedback, rating);
      handleClose();
    }
  };

  const onKeyHandler = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      event.preventDefault();
      event.stopPropagation();
      handleSend();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} data-testid="feedbackDialog">
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your feedback is important for us. Please let us help to become
            better!
          </DialogContentText>
          <Rating
            name="hover-feedback"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            type="text"
            fullWidth
            variant="outlined"
            rows={4}
            multiline
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            onKeyDown={onKeyHandler}
            inputProps={{ maxLength: 4096 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSend}
            size="small"
            variant="contained"
            endIcon={<SendIcon />}
            disabled={!feedback}
          >
            Send feedback
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FeedbackDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
