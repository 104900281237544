export const getEnvData = () => window?.ENV_CONFIG?.['endpoint-user-chatbot'];

/**
 * @constant {Object} URLs - Object containing url paths.
 * @property {string} INITIAL_MESSAGE - Url path for the initial message.
 * @property {string} SEND_MESSAGE - Url path for the all other messages after initial message.
 * @property {string} SEND_FEEDBACK - Url path for sending feedback.
 * @property {string} IPLANET_PATH - Url path to get IPlanet token.
 * @property {string} JWT_TOKEN_PATH - Url path to get JWT token.
 */
export const URLs = {
  INITIAL_MESSAGE: (partnerId = '') =>
    `${
      getEnvData().isDevelopment ? '' : getEnvData().botHostName
    }/rpa-chatbot-service/api/v1/sessions`,
  SEND_MESSAGE: (sessionId = '') =>
    `${
      getEnvData().isDevelopment ? '' : getEnvData().botHostName
    }/rpa-chatbot-service/api/v1/sessions/${sessionId}/chat-completions`,
  SEND_FEEDBACK: (sessionId = '') =>
    `${
      getEnvData().isDevelopment ? '' : getEnvData().botHostName
    }/rpa-chatbot-service/api/v1/sessions/${sessionId}/feedbacks`,
  IPLANET_PATH: () =>
    `${
      getEnvData().isDevelopment ? '' : getEnvData().iPlanetHostName
    }/openam/json/authenticate?realm=/ITSUPPORT247DATASTORE&authIndexType=service&authIndexValue=adminconsoleservice`,
  JWT_TOKEN_PATH: (partnerId = '') =>
    `${
      getEnvData().isDevelopment ? '' : getEnvData().jwtTokenHostName
    }/authorizationservice/v1/mgmt/partners/${partnerId}/users/enhancedToken`,
};

export const MESSAGE_STATUSES = {
  ROBOT_FOUND: 'robotFound',
  ABORTED: 'aborted',
  ESCALATED: 'escalated',
  IN_PROGRESS: 'inProgress',
  VIOLATION_FOUND: 'violationFound',
};
