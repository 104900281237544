import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FeedbackDialog } from '../feedback-dialog';
import { LoginDialog } from '../login-dialog';
import { useMessageContext } from '../../hooks';

const HeaderPropTypes = {
  onClose: PropTypes.func,
};

export function Header({ onClose }) {
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const sessionId = useMessageContext()[2];
  const open = Boolean(anchorElement);
  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };
  const showFeedbackDialog = () => {
    handleMenuClose();
    setShowFeedback(true);
  };
  const handleCloseFeedBack = () => {
    setShowFeedback(false);
  };
  const showLoginDialog = () => {
    handleMenuClose();
    setShowLogin(true);
  };
  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <AppBar
      position="static"
      role="heading"
      sx={{ backgroundColor: 'background.backgroundMidnightDark' }}
    >
      <Toolbar>
        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
          Help Desk Services Intelligent Assistant
        </Typography>

        {onClose ? (
          <IconButton sx={{ color: 'primary.contrastText' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
        <>
          <IconButton
            onClick={handleClick}
            sx={{ color: 'primary.contrastText' }}
            aria-label="display more actions"
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            data-testid="menu"
            anchorEl={anchorElement}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {sessionId && (
              <MenuItem onClick={showFeedbackDialog}>Leave feedback</MenuItem>
            )}
            <MenuItem onClick={showLoginDialog}>Enter token</MenuItem>
          </Menu>
        </>
      </Toolbar>
      <FeedbackDialog open={showFeedback} onClose={handleCloseFeedBack} />
      <LoginDialog open={showLogin} onClose={handleCloseLogin} />
    </AppBar>
  );
}

Header.propTypes = HeaderPropTypes;
