import { createContext, useState, useContext } from 'react';
import { getEnvData } from '../constants';
import { messageClient } from '../client';
import { formatMessages, getIsConversationFinished } from '../utils';

export function useMessages([jwtToken, endpointId, , , [[, , userPartnerId]]]) {
  // const msg = [{
  //   id: 0,
  //   issuer: 'bot',
  //   status: '',
  //   message: {
  //     text: 'Hello, how can I help you?',
  //     createdAt: (new Date()).toISOString()
  //   },
  // }];

  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [executing, setExecuting] = useState(false);
  const addMessage = async (message) => {
    const newMessage = {
      id: messages.length,
      issuer: 'user',
      status: '',
      message: {
        text: message,
        createdAt: new Date().toISOString(),
      },
    };
    const messagesFromBot = messages.filter(
      (message) => message.issuer === 'bot'
    );
    const lastMessage =
      messagesFromBot.length > 0 ? messagesFromBot.at(-1) : null;
    setMessages([...messages, newMessage]);
    let response;
    let responseMessages = [];
    try {
      if (
        !sessionId ||
        (lastMessage && getIsConversationFinished(lastMessage.status))
      ) {
        setExecuting(true);
        setSessionId(null);
        response = await messageClient.createInitialMessage({
          endpointId,
          partnerId: userPartnerId || getEnvData().partnerId,
          token: jwtToken,
        });
        const newSessionID = response?.data?.id || null;
        response = await messageClient.sendMessage({
          message: newMessage.message,
          sessionId: newSessionID,
          token: jwtToken,
        });
        setSessionId(newSessionID);
      } else {
        response = await messageClient.sendMessage({
          message: newMessage.message,
          sessionId,
          token: jwtToken,
        });
      }
      responseMessages = formatMessages(response.data, messages.length) || [];
    } catch (error) {
      console.log('Error while sending message', error);
      responseMessages = [
        {
          id: messages.length + 1,
          issuer: 'bot',
          status: '',
          message: {
            text: 'Something went wrong, please try again',
            createdAt: new Date().toISOString(),
          },
        },
      ];
    } finally {
      setExecuting(false);
    }

    setMessages((messages) => [...messages, ...responseMessages]);
  };
  return [messages, addMessage, sessionId, executing];
}

const initialMessagesContext = [[], () => {}, null, false];

export const MessagesContext = createContext(initialMessagesContext);

export function useMessageContext() {
  return useContext(MessagesContext);
}
