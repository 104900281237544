import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const RootContainerStyled = styled(Box)(({ theme }) => {
  const height = '100vh';
  return {
    backgroundColor: theme.palette.background.default,
    height,
    display: 'flex',
    flexDirection: 'column',
  };
});
